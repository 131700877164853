/* .venue-class div {
  cursor: default !important;
}  */

/* .scheduler-class table td {
  height: 142px;
} */
/* .scheduler-class{
  padding-left: 64px;
  margin-top: 25px;
} */

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gTD_u50.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gbD_u50.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gfD_u50.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.openCon {
  height: 90%;
  transition: height 0.2s ease-in-out;
}
.display-xl-none {
  display: none !important;
}
.closeCon {
  height: 85%;
  transition: height 0.2s ease-in-out;
}

.stage_img {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.stage_img img {
  width: auto !important;
  height: 305px !important;
}

.stage_cont {
  display: none !important;
}
@media screen and (max-width: 540px) {
  .openCon {
    height: 90%;
    transition: height 0.3s ease-in-out;
  }
  .closeCon {
    height: 85%;
    transition: height 0.3s ease-in-out;
  }
}

/* Pigeon Hole*/
.tabcontainer > div:first-child {
  display: none !important;
}

.chatcontent div {
  height: 100% !important;
}

.chatcontent > div > iframe {
  height: 100% !important;
}

/*Pigeonhole */

.openedStyle {
  width: 200px;
  visibility: visible;
  opacity: 1;
  max-height: 100px;
  padding-bottom: 37px;
  margin-bottom: 10px;
  transition: padding-bottom 0.5s ease-in-out, margin-bottom 0.5s ease-in-out,
    max-height 0.5s ease-in-out, visibility 1s, opacity 1s ease-in-out;
}

.closedStyle {
  width: 200px;
  visibility: hidden;
  display: none;
  opacity: 0;
  max-height: 0;
  transition: max-height 0.5s ease-in-out, visibility 0s;
}

.promo_row {
  padding-bottom: 37px;
}

.react-reveal {
  width: 100% !important;
}

.promo_col {
  height: 98px;
  background-color: #f1f1f1;
  margin-left: 48px;
  font-size: 14px;
  padding: 15px;
}

.error_promo_p {
  font-size: 12px;
  color: #fe0300;
  font-family: Rubik;
  font-weight: 100;
}

.promo_title {
  font-size: 14px;
  color: #626262;
  font-weight: bold;
  padding-bottom: 6px;
}
.promo_input {
  width: 234px;
  height: 30px;
  padding: 0px 8px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  background-size: cover;
  font-size: 16px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
}
.promo_input_Error {
  width: 234px;
  height: 30px;
  padding: 0px 8px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
  background-size: cover;
  font-size: 16px;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  border: 1px solid #fe0300;
}

.access_title {
  font-weight: bold;
  color: #343434;
}

.display-none {
  display: none !important;
}

.disabled_col {
  color: #b0b0b0;
}

.disabled_row {
  pointer-events: none;
}

.opac_ctrl {
  opacity: 0;
}

.no_prod_row {
  text-align: center;
  align-items: center;
  height: 138px;
}

.no_product_head {
  line-height: 32px !important;
}

.error {
  color: red !important;
}

.card-filter {
  width: 265px;
  margin-top: 20px;
}

.failed_txt {
  line-height: 28px !important;
}

/* .videodiv > div {
  height: 100% !important;
} */

.feature-artists-class > div > *:nth-of-type(2) > div > div {
  width: 33.33% !important;
}

.feature-artists-class > div > *:nth-of-type(3) > div {
  flex-direction: column !important;
  padding: 10px !important;
}

.feature-artists-class > div > *:nth-of-type(3) > div > *:nth-of-type(2) {
  width: 100% !important;
}

.feature-artists-class
  > div
  > *:nth-of-type(3)
  > div
  > *:nth-of-type(2)
  > *:nth-of-type(3) {
  padding-top: 17px !important;
}

.feature-artists-class
  > div
  > *:nth-of-type(3)
  > div
  > *:nth-of-type(2)
  > *:nth-of-type(1)
  > div {
  padding: 0px !important;
}

.feature-artists-class
  > div
  > *:nth-of-type(2)
  > div
  > div
  > *:nth-of-type(2)
  > *:nth-of-type(1) {
  margin-top: 10px !important;
}

.feature-artists-class
  > div
  > *:nth-of-type(2)
  > div
  > div
  > *:nth-of-type(2)
  > *:nth-of-type(2) {
  margin-top: 20px !important;
}

.feature-artists-class h6 {
  font-weight: bolder !important;
  height: 18px;
}

.feature-artists-class p {
  margin: 0;
}

.dont_access_mail {
  cursor: pointer;
  color: #5b5b5b;
  font-size: 14px;
  font-weight: bolder;
  display: block;
  padding-top: 10px;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.space_card {
  margin-bottom: 10px;
}

.passcard_modal {
  height: auto !important;
}

.pass_container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.passtypespan {
  font-weight: bold;
  color: #626262;
  font-size: 15px;
  text-align: left;
}

.passtitleheader {
  font-size: 13px;
  text-align: left;
}

i {
  font-style: italic !important;
}

.transaction_body {
  padding: 15px !important;
}

.subcription {
  margin-top: 50px;
}

.drop-in-payment h6 {
  font-weight: bold !important;
}

.drop-in-payment a {
  text-decoration: none !important;
  color: #fe0300 !important;
}

.drop-in-payment ol {
  margin-left: 32px;
}

.check_out {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.check_inner {
  margin-left: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.check_span {
  padding-left: 10px;
}

.subscribe {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.drop-in-payment label::before {
  color: #fe0300 !important;
}

.drop-in-payment input[type="checkbox"]:checked + label:before {
  border-color: #fe0300 !important;
  background-color: #fe0300 !important;
}

.sub_check path,
.drop-in-payment path {
  fill: white !important;
}

.loader_show {
  position: relative;
}

.loader-pro {
  width: 100%;
  display: none;
}

.loader-pro-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: absolute;
  background: white;
}
.loader-product-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: white;
}
.buttons-div-none {
  display: none;
}

.span_mail {
  cursor: pointer;
  color: #5b5b5b;
  font-size: 12px;
  font-weight: 500;
  display: block;
  padding-top: 10px;
}

.popover_passcard {
  background: white;
  width: 191px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 5px 3px -4px #777;
  -moz-box-shadow: 0 5px 3px -4px #777;
  box-shadow: 0 5px 3px -4px #777;
  font-weight: bold;
  color: #5f5f5f;
  word-break: break-word;
}

.popover_success_passcard {
  background: white;
  width: 191px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 5px 3px -4px #777;
  -moz-box-shadow: 0 5px 3px -4px #777;
  box-shadow: 0 5px 3px -4px #777;
  font-weight: bold;
  color: #5f5f5f;
  word-break: break-word;
  padding: 10px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.powered_by_section {
  margin-left: 80px;
}

.spantag {
  /* position: relative;
  top: -7px; */
  display: block;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  justify-content: space-between;
  padding: 0px 85px;
}

.header-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-box img {
  cursor: pointer;
}

.ptag {
  /* position: absolute;
  width: 200px;
  left: 173px;
  top: 23px;
  font-size: 16px;
  color: white; */
  width: 80%;
  font-size: 27px;
  color: white;
  margin-left: 10px;
}

.nav-header {
  padding: 0 9vw !important;
}

.mobptag {
  position: absolute;
  width: 200px;
  left: 173px;
  top: 23px;
  font-size: 16px;
  color: white;
}

.logo-div {
  margin-right: 40px;
}

.baselogo {
  display: none;
  /* width: 668px;
  cursor: pointer;
  height: 70px; */
  width: 53px;
  height: 62px;
}
.moblogo {
  display: none;
  cursor: pointer;
}
.age_button,
.age_grey_btn {
  width: 289px !important;
  height: 46px !important;
}
.age_grey_btn,
.age_grey_btn:hover,
.age_grey_btn:active {
  background-color: #969696 !important;
}
.allow_section {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  row-gap: 26px;
}
.wrap_age {
  font-size: 24px !important;
  font-weight: bolder;
}
.style-p {
  font-size: 12px !important;
  line-height: 18px !important;
}
.appFooter {
  background-color: #ffffff !important;
}
.stage_btn > span,
.feature_btn > span {
  font-weight: bolder !important;
}
.overview_body_cnt {
  background-color: #ffffff !important;
  min-height: 0 !important;
}
.css-carousel {
  display: grid;
}

.meta-data {
  display: flex;
  align-items: center;
}

.meta-details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  width: 100%;
  z-index: 2;
}

.detail-box {
  flex: 1;
}

.css-watch-free {
  padding: 0 9vw !important;
  margin-top: 33px;
}

.css-watch-free img {
  width: 100%;
}

.feature-artists-class h6 {
  font-size: 14px !important;
}

.stage-class {
  padding: 25px !important;
}

.stage-class h5 {
  line-height: 25px !important;
}

#dropdown {
  margin-left: 12px;
}

#dropdown input {
  height: 100%;
}

.show-date {
  color: #585858 !important;
  font-weight: 600 !important;
}

#dropdown input {
  max-width: 140px;
}

#dropdown_channel {
  max-width: 173px;
  margin-left: 13px;
  display: none;
}

#dropdown_channel input {
  max-width: 129px;
}

.css-1uzgb10 {
  width: 33% !important;
}

.stage_btn {
  width: 345px !important;
}

#dropdown-menu {
  top: 306px;
  left: 84.5px;
  width: 167.172px;
  border: 1px solid black;
}

#dropdown_channel-menu {
  top: 205px;
  left: 187.172px;
  width: 182.172px;
  border: 1px solid black;
}

.line-in-upcoming {
  width: 132px;
  height: 1px;
  float: left;
  fill: #000000;
  background: #ff0000;
  stroke-width: 1;
  display: block;
}

.line-in {
  margin-top: 54px;
}

.new_releases {
  font-size: 18px !important;
  color: #585858 !important;
  font-weight: bold !important;
  font-family: Montserrat !important;
  margin-top: 33px;
  margin-bottom: 16px !important;
}

.line-class {
  padding: 10px 0 !important;
}

.upcoming-title {
  height: 28px;
  font-size: 18px !important;
  color: #585858 !important;
  text-decoration: none solid rgb(88, 88, 88);
  font-weight: 600 !important;
  margin-left: 9px !important;
  margin-top: 14px !important;
}

.buy_btn {
  width: 288px !important;
}

.success_img {
  width: 100%;
  height: 343px;
}

nav > ul > li > a {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #585858 !important;
}

nav > ul > li {
  width: 20% !important;
}

.nav_header {
  background: white !important;
}

body {
  background: white !important;
}

.tag_icon {
  display: none;
}

.category-class {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 100%;
}

.category-class .active {
  background-color: #4d4d4d !important;
  color: #fff !important;
}

.category-class button {
  margin: 5px;
  height: 56px;
  border-radius: 10px !important;
  color: #000000;
  /* border-radius: 10px; */
  background: transparent;
  border: 2px solid #999999;
}

.SSO_header {
  /* height: 100px; */
  background-color: white !important;
  padding: 0 9vw !important;
}

.embed-video {
  width: 100%;
  position: relative;
  display: flex;
  height: 204px;
  flex-direction: row;
}

.tabbodycontainer {
  flex: 1;
  height: 10px;
}

.embedtabcontainer {
  flex-direction: column;
  background-color: white;
  display: flex;
  width: 490px;
  height: 100%;
}

.embedchatcontent {
  display: block;
  height: 100%;
  flex: 1;
  background-color: black;
}

.embedreactioncontainer {
  height: 39px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
}

.embedreactioncontainerdiv {
  position: absolute;
}

.embedreactioncontainerdiv > div > button {
  background-color: #ffffff00;
}

.embedreactioncontainerdiv > div {
  background-color: #ffffff00;
}

.embedtheatrediv {
  position: absolute;
  right: 8px;
  bottom: 0px;
}

.embedtheatrediv svg {
  width: 18px;
  cursor: pointer;
  fill: black;
}

/* .vodContainer {
  margin-top: 44px;
} */

.video_btn {
  width: 320px !important;
  height: 38px !important;
  padding: 0 !important;
}

.video_btn span {
  font-weight: bold !important;
}

.fail_text {
  color: #fe0300 !important;
  font-size: 26px !important;
}

.success_txt,
.redeem_txt {
  font-weight: 500 !important;
  padding: 20px !important;
}

.redeem_txt {
  line-height: 30px !important;
}

.thank_txt {
  font-weight: 100 !important;
}

.label_txt {
  font-weight: bold !important;
}

.tranform_txt {
  text-transform: capitalize;
}

.synopissection h1,
.videoDetailFeature h1 {
  font-size: 48px !important;
}

.synopissection h2,
.videoDetailFeature h2 {
  font-size: 39px !important;
}

.synopissection h3,
.videoDetailFeature h3 {
  font-size: 30px !important;
}

.synopissection h4,
.videoDetailFeature h4 {
  font-size: 25px !important;
}

.synopissection h5,
.videoDetailFeature h5 {
  font-size: 18px !important;
}

.synopissection h6,
.videoDetailFeature h6 {
  font-size: 16px !important;
}

.scheduler-class table td div {
  border-bottom: 2px solid #fff;
}

.pdf-view {
  /* padding: 10px ; */
  margin: 16px 0px 16px 0px;
}

.pdf-view iframe {
  width: 884px !important;
  height: 884px !important;
}

.pdf-view a {
  width: 100% !important;
  margin-top: 20px;
}

.pdf-view .pdf-download {
  align-items: center;
  flex-direction: column;
  display: none !important;
}

.payment-section-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerImage {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 80px;
}

.bannerImageLap {
  display: none;
}

.bannerMobile {
  display: none;
}

.addedBorder {
  width: 60%;
  padding-top: 20px;
  border-bottom: 1px solid #d4d4d4;
}

.drop-in-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropdwon-scheduler {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
}

.channel-select {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
  display: none;
}

.schedule-title svg {
  margin-left: 10px;
  fill: lightgrey;
}

.main_image {
  width: 165px;
  height: 165px;
}

.pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.product_section,
.redirect_pass_section {
  width: 100%;
  justify-content: space-between;
  flex-direction: row !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.redirect_pass_section {
  background-color: #f1f1f1;
  cursor: pointer;
  margin-bottom: 70px;
}

.gain_access_span,
.gain_free_access_span {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.gain_free_access_span {
  margin-bottom: 20px;
}

.red_font {
  color: #fe0300;
}

.info_section {
  width: 100%;
  display: inline-block;
  font-size: 11px;
  line-height: 20px;
  padding-top: 5px;
}

.info_section ul {
  padding-left: 14px;
}

.confirm_section {
  width: 100%;
  display: inline-block;
  font-weight: 100 !important;
  line-height: 17px;
  padding-top: 7px;
}

.transaction_header {
  width: auto;
  line-height: 35px !important;
  font-weight: bold !important;
}

.now-playing-class {
  background: transparent;
  padding: 0 !important;
}

.now-playing-class svg {
  width: 18px;
  height: 18px;
  padding: 0px;
  position: relative;
  top: 5px;
}

.vod-class span {
  font-size: 15px !important;
}

.card-slides {
  box-shadow: none !important;
  max-width: 380px;
}

.card-slides img {
  display: block;
  width: 380px;
  height: 213px;
  margin-bottom: 10px;
  cursor: pointer;
}

.video-slides {
  box-shadow: none !important;
  border: 1px solid #d4d4d4 !important;
  border-radius: 5px !important;
  min-height: 0 !important;
  display: flex !important;
  background-color: #ffffff !important;
  position: relative;
}

.video-slides p {
  margin: 0;
}

.video-slides img {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.title-tiles {
  font-size: 16px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 22px;
  display: block;
  font-weight: bold;
  /* word-break: break-word; */
  text-overflow: ellipsis;
  cursor: pointer;
  padding-bottom: 20px;
}

.tile-tiles p,
strong {
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
}

.title-tiles-channel {
  font-size: 16px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 22px;
  display: block;
}

.stage-class .border_bottom {
  border: none;
}

.highlight-class {
  width: 100%;
}

.highlight-class .feature-artists-class {
  padding-top: 0px !important;
}

.highlight-class-color {
  background: #f0f0f0;
}

.video-class div {
  border: none;
}

.premiers {
  font-weight: 600 !important;
}

.highlight-title {
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  margin-top: 18px;
  margin-bottom: 8px;
}

.highlight-title a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.donation-img {
  height: 33px;
  width: 179px;
  margin-right: 30px;
}

.donation-logo {
  width: 43px;
  height: 43px;
}

.booksection {
  display: flex;
}

.booksection a {
  align-items: center;
}

.booktext {
  padding: 0px !important;
}

.booktext p {
  margin: 0 !important;
}

.donation-link {
  font-weight: bold !important;
}

.feature_btn {
  color: white;
  background-color: #fe0300;
  padding: 0 15px !important;
}

.detail_btn {
  color: #fe0300;
  background-color: white;
  padding: 0 15px !important;
  border: 1px solid #fe0300 !important;
}

.banner-image {
  height: 90px;
  margin: 30px 0;
  width: 100%;
}

.live_tag_parent {
  position: relative;
  margin-bottom: 20px;
}

.live_tag {
  position: absolute;
  top: 0;
  right: 16px;
}
.card-content {
  word-spacing: 1px;
}

.live_tag img {
  width: 54px;
  height: 30px;
}

.d-flex {
  display: flex;
}

.float-right {
  float: right;
}

.top-list-card {
  background-color: white !important;
}

.top_list {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}

.top_list .card-content {
  flex: 1;
}

.top-list-card {
  display: flex !important;
  padding: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
}

.top_list {
  font-size: 14px;
  font-family: Rubik;
  margin-bottom: 10px;
  /* position:absolute; */
}
.top_list .title {
  color: #343434;
  font-weight: bold;
  background-color: transparent !important;
}
.top_list ul {
  font-family: Rubik;
  font-weight: normal;
  font-size: 14px;
  margin-top: 5px;
}
.top_list ul li {
  margin: 0 0px;
  color: #626262;
}
.top_list .subtitle {
  font-family: Rubik;
  /* font-weight: bold; */
  font-size: 14px;
  color: #343434;
  /* margin-top: 10px; */
}
.top_list small {
  font-size: 14px;
  font-weight: lighter;
  color: #626262;
}

.adjustment,
.info_section {
  text-align: justify !important;
}

.playback-container {
  margin-bottom: 60px;
  padding: 0 9vw !important;
}

.highlight-title a {
  margin-left: 57px;
}

.in-player-likes {
  padding: 0 10px;
}

.in-player-likes p {
  font-weight: bold;
}

.about_sso_div,
.about-content {
  padding: 10px 0px !important;
}

.wrap_div img {
  position: absolute;
  left: 0;
  height: auto;
  max-height: 100%;
  width: 100%;
  bottom: 0;
  z-index: -1;
  top: 0;
  opacity: 0.3;
}

.video-player {
  height: 100% !important;
}

ul.dashed {
  list-style-type: none;
  margin: 6px 10px 0 10px;
}

ul.dot {
  list-style-type: disc;
  margin: 0px 3px 0 8px;
}

ul.none {
  list-style-type: none;
  margin: 0px 3px 0 8px;
}

ul.dashed > li {
  text-indent: -20px;
}

ul.dashed > li:before {
  content: "-";
  text-indent: -20px;
  padding-right: 10px;
}

/* ul.dot > li:before {
  content: "●";
  font-size: 10px;
  text-indent: -20px;
  padding-right: 10px;
} */

.giving-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 15px;
}

.header-img {
  width: 450px;
}

.trailerDiv {
  width: 100%;
  height: 677px;
}
.pop_over_content {
  right: -24px;
}

.synopissection p {
  margin: 0;
}

.synopsis_para {
  overflow-y: auto;
  height: 300px;
}

.inviteModal {
  width: 554px !important;
  height: auto !important;
}
.inviteImage {
  width: 500px !important;
  height: 232px !important;
}
.inviteText {
  font-size: 18px !important;
  text-decoration: none solid rgb(0, 0, 0);
  line-height: 22px;
  text-align: center;
  font-weight: 1000 !important;
  margin-top: 15px;
  color: #000000 !important;
  margin-bottom: 16px !important;
}
.invitePara {
  font-family: Rubik;
  font-size: 16px;
  color: #626262;
  text-decoration: none solid rgb(98, 98, 98);
  line-height: 25px;
  font-weight: 400 !important;
  margin-bottom: 10px !important;
}
.inviteContent {
  font-family: Rubik;
  font-size: 16px;
  color: #626262;
  text-decoration: none solid rgb(98, 98, 98);
  line-height: 25px;
  font-weight: 500 !important;
  margin-bottom: 7px !important;
}
.accept-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}
.invite_banner {
  background-color: white;
  height: 55px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.invite_banner_div {
  margin-left: 47px;
  flex-grow: 9.7;
}
.invite_banner_text {
  font-family: Rubik;
  color: #000000 !important;
  font-size: 16px;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}
.invite_banner_btn {
  flex-grow: 0.3;
}

.vip_room {
  display: flex;
  align-items: center;
}
.vip_room_link {
  flex-grow: 8;
}
.vip_room_batch {
  width: 209px;
  height: 35px;
  background-color: #f9c402;
  display: flex;
  align-items: center;
}
.vip_room_star {
  flex-grow: 0.3;
  margin-left: 11px;
}
.vip_batch_text p {
  margin-bottom: 2px !important;
  color: #000000 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.vip-container {
  margin-top: 32px !important;
}

.vip_tag_star img {
  margin-top: 7px !important;
}

.vip_tag {
  width: 89px;
  height: 20px;
  background-color: #f9c402;
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 7px;
  margin-left: 7px;
}
.vip_tag_star {
  margin-left: 5px;
  margin-right: 3px;
}
.vip_tag_text p {
  color: #000000 !important;
  font-size: 12px !important;
  font-weight: 500;
}

.vip_tag img {
  height: 14px;
  width: 14px;
  margin-bottom: 0px;
  margin-top: 4px;
}

.free {
  background-color: #800020 !important;
  justify-content: center;
}

.product_section > div:first-child {
  width: 73% !important;
}

.free_event {
  width: 27% !important;
  font-size: 16px !important;
}

.price_section_web h3 {
  font-size: 20px !important;
}

.toggle-div {
  display: flex;
  justify-content: space-between;
}

.toggle-cover {
  margin-left: 20px;
}

#sort-dropdown {
  margin-left: 15px;
  height: 42px !important;
}
#sort-dropdown-menu div {
  height: 22px !important;
  border-bottom: none !important;
}
#sort-dropdown-menu div:hover {
  background-color: #1e90ff;
  color: #ffffff;
}

.card-filter input {
  border-radius: 0px !important;
  width: 29vw;
}

.sort_by_row {
  align-items: center !important;
  margin: 20px 0 35px 0 !important;
}
.toggle-div {
  margin-top: 17px;
}

.card-filter svg {
  position: absolute;
}

.live-show {
  display: flex;
  align-items: center;
  color: #cc0100;
  width: 35px;
  height: 12px;
  margin-bottom: 4px;
}

.live-show img {
  width: 8px;
  height: 8px;
  margin-bottom: 0px;
}
.live-show p {
  padding-left: 5px;
  font-size: 10px;
  font-weight: 700;
}
.livestream-title {
  padding-bottom: 5px !important;
}

.premium-tag {
  width: 89px;
  height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  margin-top: 7px;
  margin-left: 7px;
  background-color: #800020;
  justify-content: center;
}

.premium-tag-text p {
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 500;
}

.vip-container {
  margin-top: 32px !important;
}
.video-slides {
  align-items: flex-start !important;
}

.overlay_premium {
  background-color: #a1a1a1 !important;
  z-index: 3;
}

.overlay_img {
  position: absolute;
  z-index: 1;
  height: 100%;
}

.pop_over_content {
  height: 65vh !important;
  overflow-y: scroll;
  width: 243px !important;
  overflow-x: hidden;
}

.title-nav {
  cursor: default !important;
}

.title-nav img:nth-child(1) {
  cursor: pointer !important;
}

.acc-div {
  margin-left: 131px;
  margin-right: 59px;
  /* margin-left: 189px;
  margin-right: 113px; */
}
.acc-head {
  font-size: 24px !important;
  color: #000000 !important;
  font-weight: bold !important;
  font-family: Montserrat !important;
  margin-top: 39px;
  margin-bottom: 35px !important;
}

.acc-body {
  display: flex;
}
.acc-menu {
  list-style-type: none;
}
.acc-active {
  color: #fe0300 !important;
  border-right: 4px solid #fe0300;
}
.acc-list-item {
  padding: 16px 33px 16px 16px;
  font-family: Rubik;
  font-size: 16px;
  color: #626262;
  cursor: pointer;
}
.acc-line {
  opacity: 0.19968377976190477;
  width: 1px;
  border-right: 1px solid #787878;
}
.acc-right {
  margin-left: 30px;
  flex-grow: 1;
}
.acc-fields {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 73px;
}
.acc-drop {
  width: 465px;
}
.acc-details .head {
  padding-top: 15px;
  padding-bottom: 15px;
}
.acc-details .head label {
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Montserrat;
  font-size: 16px;
  color: #343434;
  font-weight: 700;
}
.acc-fields label {
  width: 223px;
  height: 17px;
  font-family: Rubik;
  font-size: 14px;
  color: #626262;
  text-decoration: none solid rgb(98, 98, 98);
  margin-bottom: 8px;
}
.acc-fields input {
  width: 392px;
  height: 36px;
  border-radius: 1px;
  fill: #ffffff;
  stroke-width: 1;
  border: 1px solid #cccccc;
  padding-left: 15px;
}
.acc-row {
  display: flex;
}
.update-btn {
  width: 159px;
}
.acc-btn {
  margin: 61px 73px 84px;
  float: right;
}

.payment-table-cover {
  width: 100%;
  padding: 15px;
  border: 1px solid #cccccc;
  margin-top: 21px;
  margin-bottom: 344px;
}
.payment-table {
  width: 100%;
}
.payment-table th {
  font-family: Rubik;
  font-size: 14px;
  color: #626262;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(204, 194, 194, 0.879);
}
.payment-table th,
tr {
  text-align: left;
}
.payment-table td {
  padding-top: 22px;
  padding-bottom: 9px;
}
.payment-table img {
  padding-right: 25px;
  height: 23px;
}
.master-card-td {
  display: flex;
  align-items: center;
}
.payment-remove {
  cursor: pointer;
  color: #fe0300;
}
.active-dot-td {
  display: flex;
  align-items: center;
}
.active-dot-td img {
  padding-right: 6px;
  height: 7px;
}
.switch-td {
  text-align: left;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fe0300;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fe0300;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.printer {
  display: flex;
  flex-direction: column;
}
.printer-div {
  position: relative;
}
.printer p {
  margin-bottom: 19px;
}

.printer img:nth-child(1) {
  position: absolute;
  width: 12px;
  height: 7px;
  left: 8px;
}
.printer img:nth-child(2) {
  position: absolute;
  width: 21px;
  height: 9px;
  left: 4px;
  left: 1;
  top: 6px;
}
.printer img:nth-child(3) {
  position: absolute;
  width: 12px;
  height: 8px;
  left: 9px;
  top: 11px;
}
.expand {
  align-self: flex-end;
}
.underline {
  border-bottom: 1px solid #cccccc;
}
.previous-order-cover {
  margin-top: 40px;
}
.upper-arrow {
  position: relative;
  float: right;
  margin-top: 14px;
}
.upper-arrow img:nth-child(1) {
  position: absolute;
  right: 4px;
}
.upper-arrow img:nth-child(2) {
  position: absolute;
  top: 6px;
  right: 4px;
}
.order-box {
  border: 1px solid #cccccc;
  padding: 18px 30px;
  margin-top: 11px;
}
.order-total {
  background-color: #f2f2f2;
}
.order-total td {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 10px;
}
.order-box-table tr {
  border-bottom: 1px solid hsl(0, 0%, 95%);
}
.order-box-table tr td {
  border-bottom: 0 !important;
  vertical-align: middle;
}
.order-box-table p {
  margin-bottom: 6px;
  font-size: 14px !important;
  font-weight: 500;
}
.order-box-table {
  font-size: 14px !important;
  width: 100%;
  margin-bottom: 15px;
}
.order-box-table > tr td:nth-child(1) {
  width: 20%;
}
.bill-cover {
  margin-bottom: 32px;
}

/* pagination */
.pagination {
  display: inline-block;
  margin-top: 22px;
  margin-bottom: 22px;
}

.pagination a {
  color: black;
  float: left;
  padding: 2px 13px;
  text-decoration: none;
}

.pagination a.active {
  background-color: red;
  color: white;
}
/* pagination */
.pagination a:hover:not(.active) {
  background-color: #ddd;
}

/* modal */
.remove_payment_modal {
  width: 554px !important;
  height: 287px !important;
  text-align: center;
}

.payment_method_proceed {
  border: 1px solid #cccccc;
  padding: 22px;
  display: flex;
  flex-grow: 1;
}
.payment_method_proceed p {
  margin-bottom: 0px;
  flex-grow: 1;
}
/* modal */

.ph-field span {
  border: 1px solid #cccccc;
  border-radius: 1px;
  padding: 8px 15px;
}

.ph-field input {
  width: 335px !important;
}

@media (max-width: 823px) {
  .embed-video {
    flex-direction: column;
    height: 410px;
  }
  .embedtabcontainer {
    height: 60%;
    width: 100%;
  }
  .chat-container {
    height: 203px !important ;
  }
  .dacast_player div:nth-child(1) {
    height: 191px !important;
  }
  .dacast {
    flex-direction: column;
  }
}

@media (min-width: 575px) and (max-width: 823px) {
  .video-player > iframe:first-child {
    height: 50% !important;
  }
  .wholediv {
    height: auto !important ;
  }
  .videodiv {
    height: 800px !important ;
  }
  /* .wholediv iframe {
    height: 33vh !important ;
  } */
  .invite_banner_btn {
    margin-right: 28px;
  }
}

@media (max-width: 574px) {
  .invite_banner {
    margin: 15px 15px 15px 19px;
  }
  .invite_banner_btn {
    margin-left: 17px;
  }
  .invite_banner_div {
    margin: 0px;
  }
  .invite_banner_div p {
    line-height: 20px;
  }
}

@media (min-width: 575px) and (max-width: 700px) {
  .invite_banner_btn {
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  .video-player > iframe:first-child {
    height: 40% !important;
  }
  .vip_room {
    background-color: #000000;
    flex-direction: column;
    align-items: flex-start;
  }
  .vip_room_batch {
    margin-left: 62px;
    margin-bottom: 22px;
  }
  .invite_banner_btn button {
    width: 139px;
    height: 41px;
    padding: 0px;
  }
  .invite_banner_btn span {
    font-weight: 700;
  }
  .livestream-dot {
    display: none !important;
  }

  .inviteModal {
    width: 100vw !important;
  }
  .inviteImage {
    height: auto !important;
  }
  .inviteImage img {
    width: 86vw !important;
    height: 25vh !important;
  }
  .accept-btn button {
    width: 100%;
  }
  .container {
    padding-bottom: 5vw !important;
  }
}

@media (min-width: 768px) {
  #sort-dropdown {
    width: 230px;
  }
}

@media (min-width: 768px) and (max-width: 769px) {
  .card-filter svg {
    margin-left: 28vw;
  }
  .card-filter input {
    padding-left: 12px !important;
  }
}

@media (max-width: 767px) {
  .toggle-div {
    flex-direction: column;
  }
  .card-filter {
    width: 100% !important;
    margin-top: 0px;
  }
  .card-filter input {
    width: 100% !important;
    padding-left: 12px !important;
  }
  .toggle-cover h5 {
    display: none;
  }
  .toggle-cover {
    margin-left: 0px;
  }
  #sort-dropdown {
    margin-left: 0px;
  }
  .toggle-cover div {
    width: 100%;
  }
  .sort_by_row {
    margin: 0px !important;
  }
  .line-in {
    margin-top: 20px;
  }

  .card-filter svg {
    margin-left: 73vw;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .card-filter input {
    width: 34vw !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .toggle-div {
    margin-top: 24px;
  }
  .show-date {
    flex-grow: 1;
  }
  #dropdown {
    width: 56vw;
  }
}

@media (min-width: 390px) and (max-width: 767px) {
  .toggle-cover {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .toggle-div {
    margin-top: 0px;
  }
  .show-date {
    flex-grow: 1;
  }
  #dropdown {
    width: 54vw;
  }
}
@media (max-width: 389px) {
  #sort-dropdown {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media (min-width: 866px) {
  .highlight-class {
    padding-right: 191px;
    padding-left: 225px;
  }
  .feature-class {
    padding-right: 175px;
    padding-left: 175px;
  }
  .highlight-class-color {
    padding: 0 180px;
  }
  .overview_body_cnt {
    padding: 0 144px !important;
  }
  @media (min-width: 668px) and (max-width: 991px) {
    .overview_body_cnt {
      padding: 0 40px !important;
    }
  }
  .live-bdy {
    padding: 0px !important;
  }
}

@media (min-width: 765px) and (max-width: 997px) {
  .highlight-class {
    padding-left: 70px;
    padding-right: 70px;
  }
  .footer_div {
    width: 100% !important;
  }
}

.container {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
}

.synopiscontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding: 5vh 5vw;
}

.bookcontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  /* z-index: 9; */
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background-color: #00254f;
}

.videoDetailFeature {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  text-align: left;
}

.videoDetailFeature h4 {
  margin-bottom: 20px;
}

.videoDetailFeature ul {
  list-style-position: inside;
}

.videoDetailFeature ul li {
  padding-left: 10px;
  padding-bottom: 30px;
}

.videoDetailFeature > h6 {
  text-transform: capitalize !important;
  font-size: 16px !important;
  /* line-height: 2px !important;
  font-weight: 500; */
}

.videoDetailFeature > p {
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
}

/* .videoDetailFeature > div > div > div {
  flex-direction: column !important;
} */

.row {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
}

.row::before {
  content: "";
  position: absolute;
  top: 0;
  background: #000000;
  left: 0;
  width: 100%;
  height: 500px;
}

.wholediv {
  height: 477px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  border-top: 1px solid #ff0000;
}

.synopisdiv {
  display: flex;
  width: 100%;
}

.bookdiv {
  display: flex;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  /* padding-top: 10px; */
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.titletext {
  line-height: 22px !important;
  margin-left: 31px;
  font-size: 16px !important;
  color: white !important;
  font-weight: 800 !important;
}

.videodiv {
  width: 70%;
  /* background-color: black; */
  /* height: 100%;
  width: 100%; */
  height: 477px;
  /* min-height: 208px; */
  /* position: relative;
  padding-bottom: 41px; */
}

.secondscreen_div {
  height: 151px;
  width: 70%;
  background-color: black;
  border-top: 3px solid white;
  padding: 23px;
}

.fullsecond_screen {
  width: 279px !important;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 23px;
  border: none;
  background-color: rgb(0, 0, 0, 55%);
}

#scroll_div::-webkit-scrollbar {
  width: 5px;
}

#scroll_div::-webkit-scrollbar-thumb {
  background: #ff0000;
}

#scroll_div::-webkit-scrollbar-track {
  background: white;
  border-left: 3px solid transparent;
  background-clip: padding-box;
}

.frame_div {
  height: 465px;
}

.icon_div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  visibility: visible;
}

.mob_icon_div {
  display: none;
}

.screen_title {
  color: white;
  font-size: 15px;
  font-weight: 800;
}

.close_div {
  height: 15px;
  display: none;
}

.arrow_div {
  height: 20px;
  display: none;
  transform: rotate(91deg);
}

.show {
  display: block;
}

.scroll_div {
  display: block;
  height: 100px;
  overflow-y: auto;
}

.fullscreen_show {
  display: block;
}

.fullscreen_close {
  display: none;
}

.content_div {
  color: white;
  padding: 15px 0px;
}

.thumb_div {
  display: flex;
  flex-flow: row;
  column-gap: 15px;
  align-items: center;
}

.img_div {
  width: 111px;
  height: 64px;
}

.thumb_cont {
  color: white;
}

.incompatible_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  padding-top: 170px;
}

/* .videodiv > div > div > video {
  height: auto !important;
} */

.text_div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  padding-right: 330px;
  padding-bottom: 0px;
  overflow: hidden;
  background-color: #000;
}

.text_div img {
  width: 100%;
  opacity: 0.25;
  min-height: 100%;
  height: auto;
}

.text_div p {
  position: absolute;
  background-color: #00000040;
}

.text_div.disablechat {
  padding-right: 0px;
  padding-bottom: 0px;
}

.audience-header-back {
  visibility: hidden !important;
}

.disablechat {
  width: 100%;
}

.chatsection {
  width: 30%;
}

.synopissection {
  width: 70%;
  padding-right: 40px;
}

.synopissection a {
  color: blue;
  text-decoration: none;
  font-style: italic;
}

.booksection {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.spread_section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  text-decoration: none;
  color: black;
}

.wrap_div,
.wrap_age_div,
.wrap_explore_div {
  padding: 50px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.96;
  z-index: 9;
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  row-gap: 50px;
  line-height: 25px;
  background-color: black;
}

.wrap_age_div {
  padding: 86px 0 0 75px;
  row-gap: 30px;
}

.wrap_explore_div {
  padding: 170px 0 0 75px;
  row-gap: 24px;
}

.pass_div {
  width: 70%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.chatQAMenu {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* border-bottom: 1px solid; */
  padding: 20px 0px 10px 0px;
}

.chatMenuTitle {
  width: 50%;
  text-align: center;
}

.chatMenuTitleSpan {
  cursor: pointer;
  height: 25px;
  padding-bottom: 7px;
  padding-right: 40px;
  padding-left: 10px;
  color: #999999;
  font-size: 12px;
  font-weight: 800;
}

.chatMenuTitle:hover .chatMenuTitleSpan {
  /* border-bottom: 6px solid #02c09e; */
  color: #000000;
}

.chatMenuTitleImage {
  width: 15px;
  height: 14px;
  margin-left: -35px;
  margin-bottom: -3px;
  filter: brightness(0);
  -webkit-filter: brightness(0);
  -moz-filter: brightness(0);
  opacity: 0.4;
}

.QAimage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 50px;
  text-align: center;
}

.chatQAMenuActive {
  /* border-bottom: 6px solid #02c09e; */
  color: #000000;
}

.chatMenuActive,
.chatMenuTitle:hover .chatMenuTitleImage {
  filter: brightness(1);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  opacity: 1;
}

.wrap_title,
.age_title {
  color: white !important;
  font-size: 18px !important;
  text-align: left;
  line-height: 28px !important;
  font-weight: bold !important;
}
.explore_title {
  color: white !important;
  width: 100%;
  font-size: 28px !important;
  text-align: left;
  line-height: 41px !important;
}

.wrap_pass,
.wrap_age {
  color: white !important;
  margin: 10px 0;
  text-align: left;
  line-height: 16px !important;
  font-size: 14px !important;
}

.wrap_age {
  min-height: 33px;
}

.wrap_login {
  color: white !important;
  margin-bottom: 10px;
  text-align: left;
}

.group_pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 21px !important;
  font-weight: 500 !important;
}

.login_btn {
  width: 288px;
  height: 46px !important;
}

.button_row {
  width: 100%;
}

.group_pass_login_section {
  line-height: 30px !important;
}

.web_schedule {
  visibility: visible;
  margin-left: 35px;
}

.mob_schedule {
  display: none;
}

.voddiv > div > div > div > div > iframe {
  width: 100% !important;
  height: 100% !important;
}

.season_radio {
  line-height: 25px;
}

.pass_price {
  display: flex;
  justify-content: flex-end;
}

.addondiv,
.overlayaddondiv {
  width: 100% !important;
}

.addondiv {
  height: 425px !important;
}

.addon_title {
  padding: 30px 20px;
}

.addonimg {
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  padding: 20px;
  align-items: center;
}

.border {
  width: 315px;
  border-bottom: 1px solid #c2c2c2;
  margin: 0px 20px;
  opacity: 0.2;
}

.menuborder {
  display: block;
  width: 315px;
  border-bottom: 1px solid #bdbdbd;
  margin: 0px 20px;
  opacity: 0.7;
}

.about-heading {
  font-weight: 600;
  font-size: 18px;
}

.about-content {
  font-family: Rubik;
  color: rgb(98, 98, 98);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 32px;
}

.digital-booklet-link {
  text-decoration: none;
}

.schedule-title {
  font-size: 24px;
  font-weight: bold;
}

.vod-title {
  margin-left: 31px;
  color: white;
}

.coming-soon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.coming-soon img {
  width: 100%;
}

.react-player__preview {
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.contestant_vote_img {
  padding-right: 10px;
  width: 25px !important;
  margin-bottom: 0px !important;
}

.contestant-tile {
  width: 33.3%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.footer_div a {
  text-decoration: none;
}

.wrap_div {
  display: flex;
  flex-direction: row;
}

.synopsis {
  flex: 2;
  margin-right: 60px;
  text-align: left;
}

.button-group {
  flex: 1;
}

.footer_text {
  font-size: 12px !important;
  font-family: Source Sans Pro !important;
  color: #000000 !important;
}

.chat-container {
  height: 100%;
  background-color: white;
}

@media (min-width: 768px) {
  .spantag {
    display: block;
    display: flex;
  }
  .user_buypass,
  .baseuserpass {
    margin-right: 148px;
  }
  .main_image {
    width: 100%;
    height: auto;
  }
  .pass_head {
    width: auto;
    line-height: 32px;
  }
  .product_section {
    width: 100%;
    justify-content: space-between;
  }
  .info_section {
    font-size: 12px;
  }
  .drop-in-payment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  .success_section {
    padding: "0px 0px 40px 40px";
  }
  .price_section_web {
    display: block;
    padding-left: 320px;
  }
  .price_section_mob {
    display: none;
    padding-left: 320px;
  }
  .bookcontainer {
    display: none;
  }
  .bookmobsection {
    display: none;
  }
  .text_div {
    font-size: 32px;
    line-height: 42px;
  }
  .mike_img {
    width: 728px;
    height: 90px;
    display: block;
  }
  .mike_mob_img {
    display: none;
  }
  .video-slides {
    width: 26.2vw !important;
    display: flex !important;
    flex-direction: column;
    cursor: pointer;
    padding: 20px 18px !important;
  }

  nav > ul > li > a:hover,
  nav > ul > li > a.active {
    border-bottom: 8px solid #ff0200 !important;
  }
  /* .SSO_Menu {
    padding-top: 30px;
  } */
}

@media (min-width: 767px) {
  .SSO_header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 620px) {
  .allow_section {
    flex-direction: column;
    row-gap: 26px;
    column-gap: 18px;
  }

  .css-watch-free {
    padding: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .SSO_header {
    padding: 15px !important;
  }

  .video-slides {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }

  .video-class {
    height: 212px !important;
    margin-top: 10px !important;
  }

  .video-slides img {
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .header-box .header-img {
    height: auto;
    width: 350px;
    padding-top: 27px !important;
  }

  .header-box .main-logo {
    height: 50px;
    width: auto;
  }
  .ptag {
    /* margin-left: -146px;
    margin-top: -6px;
    left: 196px; */
    display: none;
  }

  .mobptag {
    margin-left: -146px;
    margin-top: -13px;
    left: 196px;
  }
  .moblogo {
    display: block;
    padding-top: 5px;
    padding-left: 0px;
  }
  .banner-image {
    width: 100%;
    margin: 15px;
  }

  .stage_btn {
    width: 100% !important;
    margin: 5px;
  }
  .pdf-view .pdf-download {
    display: flex !important;
  }
  .pdf-view iframe {
    display: none !important;
  }
  .SSO_header {
    width: 100% !important;
  }

  #dropdown_123 {
    flex-direction: column !important;
    padding: 10px !important;
  }

  .group_pass_head {
    width: auto;
    font-size: 18px !important;
    line-height: 30px !important;
    padding-bottom: 24px;
  }
  #dropdown_channel {
    display: flex;
  }
  #dropdown {
    display: flex;
    margin-top: 0px;
  }
  .group_pass_login_section {
    line-height: 23px !important;
    font-size: 15px !important;
  }
  .button_row {
    padding-top: 20px;
  }
  .payment-section-text {
    display: block;
  }
  .payment-section-text h3 {
    font-size: 18px;
    color: #000000;
    text-decoration: rgb(0, 0, 0);
  }
  .braintree-sheet {
    width: 95%;
  }
  .css-xq2s6o {
    width: 95%;
  }
  .drop-in-payment {
    padding: 15px;
  }
  .try_again_btn {
    width: 100%;
  }
  .transaction_header {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .price_section_web {
    display: none;
    padding-left: 320px;
  }
  .price_section_mob {
    display: block;
    padding-left: 320px;
  }

  .failed_txt {
    font-size: 14px !important;
  }

  .confirm_section {
    line-height: 21px !important;
    padding-top: 20px !important;
  }

  /* .row {
    margin-top: 80px;
  } */

  /* .row::before {
    background: black;
    height: 250px;
  } */
  .container {
    width: 100%;
    padding: 0px;
  }

  .synopiscontainer {
    width: 100%;
    padding: 0px;
    margin-bottom: 40px;
    margin-top: 15px;
  }

  .bookcontainer {
    width: 100%;
    display: block;
  }
  .title {
    display: flex;
    align-items: center;
    padding-top: 75px;
    padding-left: 17px;
    /* padding-bottom: 15px; */
  }
  .wholediv {
    flex-direction: column;
    border: none;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .bookdiv {
    flex-direction: column;
  }

  .videodiv {
    width: 100%;
    min-height: 208px;
    position: relative;
    /* padding-bottom: 41px; */
  }
  .frame_div {
    width: 100%;
    height: 100%;
  }
  .text_div {
    padding-right: 0px;
    padding-bottom: 465px;
  }
  .text_div.disablechat {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .synopissection {
    width: 100%;
    padding: 24px 15px 5px 14px;
  }

  .chatsection {
    width: 100%;
  }

  .bookwebsection {
    padding: 0 15px;
  }

  .synopissection {
    padding: 0px;
  }

  .bookmobsection {
    display: block;
  }

  .wrap_div {
    padding: 30px 25px 0 25px;
    row-gap: 20px;
  }
  .wrap_age_div {
    padding: 77px 25px 0 25px;
    row-gap: 20px;
  }
  .wrap_explore_div {
    padding: 75px 25px 0 42px;
    row-gap: 27px;
  }

  .wrap_title,
  .explore_title,
  .age_title {
    width: 100%;
    line-height: 28px !important;
    font-size: 22px !important;
  }

  .wrap_pass,
  .wrap_login {
    font-size: 17px !important;
    height: auto;
    min-height: auto;
  }

  .wrap_age {
    font-size: 22px !important;
  }

  .login_btn {
    width: 100%;
  }

  .mike_mob_img {
    width: 350px;
    height: 50px;
    display: block;
  }

  .mike_img {
    display: none;
  }

  .channel-select {
    display: block;
  }

  .web_schedule {
    display: none;
  }

  .mob_schedule {
    display: block;
    padding-bottom: 100px;
    padding-left: 15px;
  }

  .card-slides {
    padding: 0px !important;
  }

  .thumb_div {
    flex-flow: column;
    row-gap: 15px;
    align-items: flex-start;
  }
  .secondscreen_div {
    width: 279px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 23px;
    border: none;
    background-color: rgb(0, 0, 0, 55%);
  }
  .mob_icon_div {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    visibility: visible;
  }
  .icon_div {
    display: none;
  }
  .scroll_div {
    display: none;
  }
  .scorll_show {
    display: block;
  }
  .tag_icon {
    display: block;
    width: 15px;
    height: 15px;
    filter: brightness(0);
    -moz-filter: brightness(0);
    -webkit-filter: brightness(0);
    opacity: 0.7;
  }
  a.active .tag_icon {
    filter: brightness(1);
    -moz-filter: brightness(1);
    -webkit-filter: brightness(1);
    opacity: 1;
  }
  .pass_div {
    width: 100%;
    row-gap: 22px;
    flex-flow: column;
  }
  nav > ul {
    width: 100% !important;
  }
  nav > ul > li {
    width: 20% !important;
  }
  nav > ul > li > a {
    font-size: 8px !important;
    padding: 0px !important;
  }
  .buy_btn {
    width: 100% !important;
  }
  .success_img {
    height: auto;
  }
  .highlight-title {
    font-size: 23px !important;
    line-height: 27px !important;
    margin-left: 28px !important;
  }
  .videoDetailFeature {
    padding: 0px 15px 20px 0px;
  }
  .title {
    padding-top: 25px;
  }

  .booksection a {
    flex-direction: column;
  }
  .live-show p {
    margin-bottom: 9px;
  }
}

.message {
  position: relative;
  z-index: 999;
}

.css-0 {
  position: relative;
  z-index: 999;
}

.css-gwpmru {
  position: relative;
  z-index: 9;
}

@media only screen and (max-width: 380px) {
  .card-slides img,
  .video-slides img {
    width: 100%;
  }

  .video_btn {
    width: 100% !important;
  }
}

@media only screen and (max-width: 320px) {
  .card-filter {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .category-class {
    display: contents !important;
  }
  .live-grid {
    width: 100% !important;
    margin: 0 auto;
  }
  .live-bdy {
    padding: 0px !important;
  }
  .stage_img {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media (min-width: 320px) and (max-width: 553px) {
  .bannerMobile {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 553px) {
  .bannerImageLap {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 320px) and (max-width: 765px) {
  .bannerImage {
    margin-top: 40px;
  }
  .bannerImage img {
    margin-top: 22px;
  }
  .addedBorder {
    width: 100%;
  }
  .overlayaddondiv {
    height: 300px !important;
  }
  .appFooter {
    height: auto !important;
    /* height: 100vh !important; */
    /* bottom: 0 !important; */
  }
  .contestant-tile {
    width: 50%;
  }
}

@media only screen and (max-width: 359px) {
  #dropdown_123 {
    flex-direction: column !important;
    padding: 10px !important;
  }
  #dropdown_channel {
    margin-left: 12px;
  }
}
@media (min-width: 575px) {
  #dropdown_123 {
    flex-direction: row !important;
    padding: 10px !important;
  }
  .css-livenow {
    padding-left: 132px !important;
    padding-top: 60px !important;
  }
  .scheduler-class {
    margin-left: -64px !important;
  }
}
@media (min-width: 575px) {
  .css-carousel {
    padding-top: 0px;
  }
  .upcoming-title {
    margin-top: 60px !important;
  }
}
@media (max-width: 575px) {
  .row::before {
    display: none;
  }
  .videodiv {
    background-color: black;
    height: 800px;
  }
  .voddiv {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: black;
  }
  .css-carousel {
    padding-top: 0px;
  }
  .upcoming-title {
    margin-top: 60px !important;
  }
  .contestant-tile {
    width: 100%;
  }
  .playback-container {
    padding: 175px 9vw 0 9vw !important;
  }
  .css-watch-free {
    padding: 167px 9vw 0 9vw !important;
    margin-top: 0px;
  }
  .vodContainer {
    padding-top: 144px;
  }
  .title {
    padding: 15px;
    background-color: black;
    /* padding-top: 18px;
    padding-bottom: 0px; */
  }
  .wholediv {
    height: auto;
  }
  .vod-title {
    margin-left: 21px;
    text-align: left;
  }
  .wrap_div {
    flex-direction: column;
  }
  .coming-soon {
    display: none !important;
  }
  .coming-soon-desk {
    display: none !important;
  }
  .coming-soon-mob {
    margin-bottom: 67px !important;
  }
  .trailerDiv {
    height: 29.78vh;
    width: 93.5vw;
  }
  .pop_over_content {
    display: block !important;
    height: 280px;
  }
  .digital-booklet-link {
    width: 100%;
  }
  .digital-booklet-link button {
    width: 100%;
  }

  .no-nav {
    margin-bottom: 10px !important;
  }

  .appFooter {
    padding: 0 25px;
  }
  .synopsis_para {
    height: auto !important;
  }
}
@media (min-width: 866px) {
  .css-livenow {
    padding-left: 128px !important;
    padding-right: 150px !important;
    padding-top: 41px !important;
  }
  .upcoming-title {
    margin-top: 26px !important;
  }
  .scheduler-class {
    margin-left: 20px !important;
  }
}
@media (max-width: 766px) {
  .css-livenow {
    padding-left: 11px;
  }
}
@media (max-width: 576px) {
  .header-img {
    width: 300px !important;
    height: auto !important;
    padding-top: 20px !important;
  }
  .SSO_header {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 93px !important;
    padding: 21px !important;
  }
  .poweredby_tab {
    position: fixed;
    margin-top: 93px !important;
  }
  .stage-class {
    padding: 147px 8px 8px 8px !important;
  }
  .SSO_header {
    padding: 15px !important;
  }
}

@media (max-width: 425px) {
  .popovercontent {
    display: none;
  }
  .popover_passcard {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .dont_access_mail {
    padding-top: 0px;
  }

  .popover_success_passcard,
  .popover_passcard {
    width: 90% !important;
    height: 100% !important;
    margin-top: 18px !important;
    margin-bottom: 10px !important;
  }

  .passcard_modal hr {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .stage-class h5 {
    font-size: 16px !important;
  }
  .stage_btn {
    width: 100% !important;
  }
  .stage_btn > span {
    font-size: 16px !important;
  }
  .feature_btn {
    padding: 10px !important;
  }
  .watchfree-tiles {
    width: 100%;
  }
}

@media (min-width: 765px) {
  .baselogo {
    display: block;
  }
}

@media (max-width: 575px) {
  .css-carousel {
    padding-top: 0px;
  }
  .upcoming-title {
    margin-top: 60px !important;
  }
  .contestant-tile {
    width: 100%;
  }
  .playback-container {
    padding: 175px 7vw 0 7vw !important;
  }

  .synopissection {
    width: 100%;
    padding: 0 7vw;
  }

  .chatsection {
    padding: 0 7vw;
  }

  .bookwebsection {
    padding: 0 7vw;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .chatsection {
    width: 100%;
  }

  .wrap_div div {
    width: 100%;
  }

  .wrap_div,
  .wrap_age_div,
  .wrap_explore_div {
    row-gap: 0px !important;
    grid-row-gap: 20px !important;
    position: relative !important;
    padding: 9vw;
  }

  .chatsection {
    margin: 20px 0;
  }
}

@media (min-width: 575px) and (max-width: 1367px) {
  .contestant-tile {
    width: 50%;
  }
  .video-slides {
    width: 100% !important;
    margin: 0 10px;
  }

  .container {
    width: auto !important;
    padding: 5vw;
  }

  .synopiscontainer {
    width: auto !important;
    padding: 2vh 9vw;
  }

  .synopissection {
    width: 100%;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .chatsection {
    width: 100%;
  }

  .wrap_div,
  .wrap_age_div,
  .wrap_explore_div {
    row-gap: 0px !important;
    grid-row-gap: 20px !important;
    position: relative !important;
    padding: 5vw;
  }

  .chatsection {
    margin: 20px 0;
  }
}
@media (min-width: 765px) and (max-width: 1200px) {
  .video-class {
    height: 344px !important;
    padding-top: 0px !important;
  }
  .highlight-title {
    margin-left: 0px !important;
  }
  .stage_btn {
    padding: 0px !important;
  }
  .footer_text {
    font-size: 12px !important;
  }
  .privacy_link {
    font-size: 12px !important;
  }
  .each_div0 {
    padding: 10px 6px 6px 6px !important;
  }
  .highlight-class {
    margin-bottom: 60px;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .coming-soon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .coming-soon-desk {
    display: none !important;
  }
  .coming-soon-mob {
    display: none !important;
  }
  .trailerDiv {
    width: 100%;
    height: 345px;
  }
}

@media (min-width: 1201px) {
  .coming-soon {
    display: none !important;
  }
  .coming-soon-desk {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .coming-soon-mob {
    display: none !important;
  }
}

@media (max-width: 480px) {
  .poweredby_tab {
    position: fixed;
    margin-top: 93px !important;
  }

  .stage_btn {
    padding: 0px !important;
  }
  .highlight-title {
    font-size: 24px !important;
    margin-left: 0px !important;
  }
  .highlight-title a {
    margin-left: 0px;
  }
  .footer_text {
    font-size: 12px !important;
    color: #383333 !important;
  }
  .privacy_link {
    font-size: 12px !important;
  }
  .each_div0 {
    padding: 2px !important;
  }
  .each_div1 {
    padding: 2px !important;
  }
  .each_div2 {
    padding: 7px !important;
  }
  .appFooter {
    margin-bottom: 70px !important;
  }
  .no-nav {
    margin-bottom: 10px !important;
  }
  .highlight-class {
    margin-bottom: 30px;
  }
  .nav-header {
    padding: 0 2px !important;
  }
  .SSO_Menu a {
    font-size: 12px !important;
    font-family: "Rubik" !important;
    font-weight: normal !important;
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 650px;
}

.oops_txt > p {
  width: 100%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.oops_txt {
  font-size: 22px !important;
  line-height: 30px !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  flex-direction: column;
}

.stream_txt {
  font-size: 18px !important;
  line-height: 30px !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show_design {
  padding-left: 20px;
}

.date_design {
  padding-left: 26px;
}

.time_design {
  padding-left: 21px;
}

.invite_watch {
  color: "#fe0300";
  text-decoration: "none";
}

.invite_loading {
  width: "100%";
  display: "flex";
  align-items: "center";
  justify-content: "center";
}

.invite_content {
  margin-bottom: 0px;
}

@media (max-width: 574px) {
  .safari .tabcontainer div:nth-child(3) {
    position: relative;
    /* margin-top: 195px; */
  }
}

@media (max-width: 822px) {
  .safari .tabcontainer div:nth-child(3) {
    position: relative;
    /* margin-top: 195px; */
  }
}

@media (min-width: 824px) and (max-width: 830px) {
  .safari .synopiscontainer {
    margin-top: 5%;
  }
}

@media (min-width: 801px) and (max-width: 823px) {
  .safari .synopiscontainer {
    margin-top: 20%;
  }

  .safari .playback {
    margin-top: 20px !important;
  }

  .playback {
    /* background-color: red; */
  }
}

@media (min-width: 665px) and (max-width: 800px) {
  /* .safari .synopiscontainer {
    margin-top: 20%;
  } */

  .vip-room .safari .synopiscontainer {
    margin-top: -5%;
  }

  .safari .playback {
    margin-top: 20px !important;
  }
}

@media (min-width: 575px) and (max-width: 664px) {
  /* .safari .synopiscontainer {
    margin-top: 30%;
  } */

  .vip-room .safari .synopiscontainer {
    margin-top: -5%;
  }

  .safari .playback {
    margin-top: 20px !important;
  }
}

@media (min-width: 520px) and (max-width: 574px) {
  /* .synopiscontainer {
    margin-top: 55%;
  } */

  .vip-room .synopiscontainer {
    margin-top: 0%;
  }

  .playback {
    margin-top: 20px !important;
  }
}

@media (min-width: 435px) and (max-width: 519px) {
  /* .synopiscontainer {
    margin-top: 70%;
  } */

  .vip-room .synopiscontainer {
    margin-top: 5%;
  }

  .playback {
    margin-top: 20px !important;
  }
}

@media (min-width: 330px) and (max-width: 434px) {
  /* .synopiscontainer {
    margin-top: 80%;
  } */

  .vip-room .synopiscontainer {
    margin-top: 5%;
  }

  .playback {
    margin-top: 20px !important;
  }
}

@media (min-width: 300px) and (max-width: 329px) {
  /* .synopiscontainer {
    padding-top: 90%;
  } */

  .vip-room .synopiscontainer {
    padding-top: 5%;
  }

  .playback {
    padding-top: 20px !important;
  }
}

@media (min-width: 573px) and (max-width: 823px) {
  .vip-room .safari .synopiscontainer {
    margin-top: -5% !important;
    background-color: red;
  }

  .vip-room .safari .tabcontainer > :last-child {
    /* margin-top: 220px !important; */
  }
}

@media (max-width: 572px) {
  .vip-room .safari .synopiscontainer {
    margin-top: 5% !important;
  }
}

@media (max-width: 535px) {
  .acc-div .acc-head {
    margin-top: 180px !important;
  }

  .acc-div {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .acc-row {
    gap: 0 !important;
  }

  .acc-head {
    margin-top: 180px;
  }

  .acc-fields {
    padding-right: 0;
  }

  .acc-details > .acc-fields {
    padding-top: 0;
  }

  .acc-details .head {
    display: none;
  }

  .acc-details .acc-row {
    display: flex;
    flex-direction: column;
  }

  .acc-details .acc-row .acc-fields input,
  .acc-details .acc-row .acc-drop,
  .acc-details .acc-btn {
    width: 100%;
  }

  .acc-details .acc-row .acc-fields .ph-field-input {
    display: flex;
  }

  .acc-details .acc-row .acc-fields .ph-field-input span {
    padding: 6px 15px;
  }

  .acc-details .acc-btn,
  .acc-right .acc-btn {
    margin: 56px 0 52px;
  }

  .acc-right .acc-btn {
    float: none;
  }

  .acc-details .acc-btn .update-btn,
  .acc-right .acc-btn .update-btn {
    width: 100%;
  }

  /*** My account Tables ***/
  .payment-methods-table {
    border: 1px solid #cccccc !important;
    padding: 14px;
  }

  .acc-right .payment-table-cover,
  .pass-subscription-table-cover {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }

  .acc-right .payment-table-cover table,
  thead,
  tbody,
  th,
  td,
  tr,
  .pass-subscription-table-cover table,
  thead,
  tbody,
  th,
  td,
  tr,
  .flexible-table tr {
    display: block;
  }

  .acc-right .payment-table-cover table tr:first-child,
  .pass-subscription-table-cover table tr:first-child {
    display: none;
  }

  .acc-right .payment-table-cover table td,
  .pass-subscription-table-cover table td {
    border: none;
    position: relative;
    padding-left: 50%;
    padding-top: 0;
  }

  .acc-right .payment-table-cover table td:first-child {
    border-bottom: 1px solid #ccc;
    padding-left: 0%;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .pass-subscription-table-cover table td:first-child {
    border-bottom: 1px solid #ccc;
    padding-left: 0%;
    justify-content: space-between;
  }

  .pass-subscription-table-cover table td:nth-child(2) {
    border-bottom: 1px solid #ccc;
    padding-left: 70% !important;
  }

  .acc-right .payment-table-cover table td:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    padding-right: 10px;
    margin-top: 0;
    white-space: nowrap;
    color: #626262;
  }

  .pass-subscription-table-cover table td:before {
    width: 0 !important;
  }

  .pass-subscription-table-cover table tr td:first-child {
    display: flex;
  }

  .pass-subscription-table-cover .switch-td {
    padding-left: 15% !important;
  }

  .acc-right .payment-table-cover table td:nth-of-type(2):before {
    content: "Ending In";
  }

  .acc-right .payment-table-cover table td:nth-of-type(3):before {
    content: "Expires";
  }

  .acc-right .payment-table-cover table td:nth-of-type(4):before {
    content: "Action";
  }

  .acc-right .payment-table-cover table tr .master-card-td span img {
    padding-top: 5px;
  }

  /* ---- */
  .pass-subscription-table-cover table td:nth-of-type(1):before {
    content: "" !important;
    font-weight: bold;
  }

  .pass-subscription-table-cover table td:nth-of-type(2):before {
    content: "" !important;
  }

  .pass-subscription-table-cover table td:nth-of-type(3):before {
    content: "Expiry Date" !important;
  }

  .pass-subscription-table-cover table td:nth-of-type(4):before {
    content: "Amount" !important;
  }

  .pass-subscription-table-cover table td:nth-of-type(5):before {
    content: "Auto-renew" !important;
  }

  .pass-subscription-table-cover table tr .master-card-td span img {
    padding-top: 5px;
  }

  .payment-table-cover,
  .pass-subscription-table-cover table {
    border: 1px solid white;
  }

  .pass-subscription-table-cover table tr {
    border: 1px solid #cccccc;
    padding: 14px;
    margin-top: 20px;
  }

  .pass-subscription-table-cover table tr {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 24px;
  }

  .pass-sub-active-status {
    display: inline-block !important;
  }

  .display-mobile-none {
    display: none;
  }

  .d-inline {
    margin-left: 20px;
    display: inline !important;
  }

  /* billing history */
  .order-box-table tr {
    padding-bottom: 10px;
  }

  .order-total {
    display: flex !important;
  }

  .flexible-td-col {
    width: auto !important;
  }

  .flexible-table {
    display: flex;
    flex-wrap: wrap !important;
  }

  .flexible-table tr:nth-child(1) {
    flex-grow: 1 !important;
    width: 50% !important;
  }

  .flexible-table tr:nth-child(2) {
    flex-grow: 1 !important;
    width: 50% !important;
  }

  .flexible-table tr th {
    border: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .flexible-table tr:nth-child(2) td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .flexible-table .order-total {
    margin-top: 20px;
  }

  /* No column th */
  .flexible-table > tr th:first-child {
    display: none;
  }

  /* Item column th */
  .flexible-table > tr th:nth-child(2) {
    display: none;
  }

  /* No column td */
  .flexible-table tr:nth-child(2) td:first-child {
    display: none;
  }

  /* Item column td */
  .flexible-table tr:nth-child(2) td:nth-child(2) {
    display: none;
  }

  .flexible-table tr:nth-child(2) td {
    padding: 0;
    text-align: right;
  }

  .flexible-table .order-total {
    display: flex !important;
    flex-wrap: nowrap !important;
    width: 100%;
  }

  /* app footer */
  .appFooter {
    bottom: 0 !important;
  }
}

@media (max-width: 768px) {
  /* pesonal details */
  .acc-row {
    display: flex;
    flex-direction: row;
    gap: 58px;
  }

  .acc-details .acc-row div {
    width: 100%;
  }

  .acc-row .acc-fields {
    padding-right: 0;
  }

  .acc-fields input {
    width: 100%;
  }

  .acc-right .payment-table-cover,
  .pass-subscription-table-cover {
    width: 100%;
  }

  .acc-details .acc-row .acc-fields .ph-field-input {
    display: flex;
    width: 100% !important;
  }

  .acc-details .acc-row .acc-fields .ph-field-input input {
    width: 100% !important;
  }

  .acc-details .acc-row .acc-fields .ph-field-input span {
    padding: 6px 15px;
  }

  .acc-details .acc-row .acc-fields {
    width: 100% !important;
  }

  .acc-btn {
    margin-right: 30px;
  }

  .pass-subscription-table-cover .switch-td {
    padding-left: 50% !important;
    float: left !important;
  }

  .pass-subscription-table-cover table tr {
    border-bottom: 1px solid #ccc !important;
    padding-bottom: 40px;
  }

  .pass-subscription-table-cover table tr td {
    /* border-bottom: 1px solid #ccc !important; */
  }

  /****** menu bar ******/
  .acc-div {
    margin-left: 45px;
    margin-right: 45px;
  }

  .acc-right {
    margin-left: 0;
  }

  .acc-head {
    margin-top: 39px;
  }

  .acc-menu {
    display: flex;
  }

  .acc-menu {
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 30px;
    border-bottom: 1px solid #7878783c;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .acc-menu li:last-child {
    margin-right: 0 !important;
  }

  .acc-menu::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .acc-menu .acc-list-item {
    display: inline;
    font-size: 16px;
    font-family: Rubik;
    padding: 16px 0;
    margin-right: 16px;
    cursor: pointer;
  }

  .acc-active {
    color: #fe0300;
    border-right: 0;
    border-bottom: 4px solid #fe0300;
  }

  .acc-body {
    flex-direction: column;
  }

  .appFooter {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
}

@media (min-width: 536px) and (max-width: 576px) {
  .acc-div .acc-head {
    margin-top: 180px !important;
  }
}

@media (min-width: 536px) and (max-width: 767px) {
  .pass-subscription-table-cover .switch-td {
    padding-left: 0 !important;
    float: left;
  }
}

.pass-sub-active-status {
  display: none;
}

.d-inline {
  display: none;
}

.pass-subscription-table-cover table tr {
  border-bottom: 1px solid #f2f2f2;
}

.pass-subscription-table-cover table tr:last-child {
  border: 0 !important;
}

/* desktop */
@media (min-width: 1200px) {
  .display-xl-none {
    display: none !important;
  }

  .display-xl-show {
    display: block;
  }

  .display-xl-show > div {
    display: flex;
  }
}

/* tablet */
@media (min-width: 361px) and (max-width: 1199px) {
  /* .display-xl-none {
    display: flex !important;
  } */

  /* .display-xl-none > div {
    display: flex;
  } */

  /* don't touch this */
  .display-xl-show {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .top-list-card button {
    width: 100% !important;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  /* .display-xl-none > div {
    display: block !important;
  } */
}

/* mobile */
@media (max-width: 360px) {
  /* .display-xl-none {
    display: none !important;
  } */

  .display-xl-show {
    display: flex !important;
  }
}

.theoplayer-poster {
  z-index: 0 !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.OuterContainerStyle {
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
  width: 220px;
}
.OuterContainerStyleEnableTen {
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 14px;
  width: 330px;
}
.ButtonWidth {
  width: 100%;
}

.Discount-Image-Style {
  width: 100%;
  /* height: 600px; */
}
@media screen and (max-width: 580px) {
  .OuterContainerStyle {
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    width: 100%;
  }
  .OuterContainerStyleEnableTen {
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    width: 100%;
  }
  .top_list ul li {
    margin: 6px 18px;
    color: #626262;
  }

  .top_list .title {
    color: #343434;
    font-weight: bold;
    font-size: 16px;
    background-color: transparent !important;
  }
  .card-content {
    margin-bottom: 10px;
  }
}

@media (min-width: 668px) and (max-width: 991px) {
  .overview_body_cnt {
    background-color: #ffffff !important;
    min-height: 0 !important;
    padding: 0 !important;
  }
  .OuterContainerStyle {
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    width: 220px;
  }
  .OuterContainerStyleEnableTen {
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 14px;
    width: 100%;
  }
}

.AnotherStyleClass {
  display: flex;
  align-items: flex-end;
  justify-self: flex-end;
}
.AnotherStyleClassForm {
  width: 95%;
}
@media (min-width: 668px) and (max-width: 991px) {
  .acc-menu {
    display: flex;
  }
  .acc-menu {
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 30px;
    border-bottom: 1px solid #7878783c;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .acc-active {
    color: #fe0300;
    border-right: 0;
    border-bottom: 4px solid #fe0300;
  }
  .acc-body {
    flex-direction: column;
  }
  .acc-div {
    margin-left: 45px;
    margin-right: 59px;
  }
  .acc-row {
    display: flex;
    flex-direction: row;
    grid-gap: 58px;
    gap: 58px;
  }
  .acc-details .acc-row .acc-fields {
    width: 100% !important;
  }
  .acc-fields {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 0px;
  }
  .acc-fields input {
    width: 100%;
  }
  .acc-details .acc-row .acc-fields .ph-field-input {
    display: flex;
    width: 100% !important;
  }
  .acc-details .acc-row .acc-fields .ph-field-input input {
    width: 100% !important;
  }
  .acc-btn {
    margin: 5% 0 5% 0;
    float: right;
  }
}

.InputBoxNumber {
  background-color: #f2f2f2;
  border: 2px solid #cccccc;
  fill: #f2f2f2;
  color: #a8a8a8;
  /* stroke-width: 1; */
}
.EditButton-LinkStyle {
  text-decoration: none;
}
